<template>
  <cellV1
    :leftClass="leftClass"
    :leftIconShow="leftIconShow"
    :leftIcon="leftIcon"
    :leftIconClass="leftIconClass"
    :leftText="leftText"
    :leftTextClass="leftTextClass"
    :rightIconShow="rightIconShow"
    :rightClass="rightClass"
    :rightIcon="rightIcon"
    :rightIconClass="rightIconClass"
    :rightText="rightText"
    :rightTextClass="rightTextClass"
    @onRight="onRightClick"
    @onLeft="onLeftClick"
    @onCell="onCellClick"
  >
    <slot></slot>
  </cellV1>
</template>

<script>
import cellV1 from "@/uicomponent/cell/cellV1.vue";
export default {
  name: "cellV2",
  components: {
    cellV1,
  },
  props: {
    //模板类型
    type: {
      type: Number,
      default: 0,
    },
    leftText: {
      type: String,
      default: "",
    },
    leftIcon: {
      type: String,
      default: "",
    },
    rightIcon: {
      type: String,
      default: "",
    },
    rightText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      leftClass: ["left"],
      leftIconShow: false,
      leftIconClass: ["left_img"],
      leftTextClass: [" text_family-r text_size-14 text_weight-400"],
      rightIconShow: false,
      rightClass: ["right"],
      rightIconClass: ["right_img"],
      rightTextClass: [
        "box-m-r-10 text_size-14 text_family-r text_weight-400 text_color-a2a2a2",
      ],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      switch (this.type) {
        case 1:
          {
            this.leftTextClass = [
              " text_family-r text_size-14 text_weight-400 text_color-939393",
            ];
            this.rightTextClass = [
              " text_size-14 text_family-s text_weight-600 text_color-f46a17",
            ];
            this.rightIconShow = false;
          }
          break;
        case 2:
          {
            this.leftClass = ["left width-70"];
            this.leftTextClass = [
              "box-m-l-4 text_size-12 text-family-r text_weight-400 text_color-939393",
            ];
            this.leftIconClass = ["left_img img-18"];
            this.leftIconShow = true;
            this.rightClass = ["right direction-row"];
            this.rightTextClass = [
              " text_size-12 text_family-s text_weight-400 text_color-f46a17",
            ];
            this.rightIconClass = ["right_img img-14 box-m-r-2"];
            this.rightIconShow = true;
          }
          break;
        case 3:
          {
            this.leftTextClass = [
              " text_size-14 text_family-r text_weight-400 color-333",
            ];
            this.rightTextClass = [
              " text_size-14 text_family-r text_weight-400 text_color-333",
            ];
          }
          break;
        case 4:
          {
            this.leftTextClass = [
              " text_size-14 text_family-r text_weight-400 color-333",
            ];
            this.rightTextClass = [
              " text_size-16 text_family-s text_weight-600 text_color-c10000",
            ];
          }
          break;
        case 5:
          {
            this.leftIconShow = false;
            this.leftTextClass = [
              " box-m-l-4 text_size-12 text-family-r text_weight-400 text_color-939393",
            ];
          }
          break;
        case 6:
          {
            this.leftIconShow = false;
            this.leftTextClass = [
              " text_size-14 text_family-r text_weight-400 color-333",
            ];
            this.rightTextClass = [
              " text_size-16 text_family-s text_weight-600 text_color-333",
            ];
          }
          break;
        case 7:
          {
            this.leftIconShow = false;
            this.leftTextClass = [
              " text_size-14 text_family-r text_weight-400 color-333",
            ];
            this.rightTextClass = [
              " text_size-16 text_family-s text_weight-600 text_color-333",
            ];
            this.rightIconClass = ["right_img img-11 box-m-l-7"];
            this.rightIconShow = true;
          }
          break;
        case 8:
          {
            this.leftIconShow = false;
            this.leftTextClass = [
              " text_size-14 text_family-r text_weight-400 color-333",
            ];
            this.rightIconShow = true;
            this.rightTextClass = [
              " text_size-14 text_family-s text_weight-600 text_color-333",
            ];
            this.rightIconClass = ["right_img img-11 box-m-l-7"];
          }
          break;
        case 9:
          {
            this.rightClass = ["right hide"];
            this.leftIconShow = false;
            this.leftTextClass = [
              " text_size-14 text_family-r text_weight-400 color-333",
            ];
          }
          break;
        case 10:
          {
            this.leftIconShow = false;
            this.rightIconShow = "true";
            this.leftTextClass = [
              "text_size-14 text_family-s text_weight-600 color-333",
            ];
            this.rightIconShow = true;
            this.rightTextClass = [
              "text_size-14 text_family-r text_weight-400 text_color-f46a17",
            ];
            this.rightIconClass = ["right_img img-21"];
            this.rightClass = ["right "];
          }
          break;
        case 22:
        {
          this.leftClass = ["left width-70"];
          this.leftTextClass = [
            "box-m-l-4 text_size-12 text-family-r text_weight-400 text_color-939393",
          ];
          this.leftIconClass = ["left_img img-18"];
          this.leftIconShow = true;
          this.rightClass = ["right direction-row"];
          this.rightTextClass = [
            " text_size-12 text_family-s text_weight-400 text_color-f46a17",
          ];
          this.rightIconClass = ["right_img img-14 box-m-r-2"];
          this.rightIconShow = false;
        }
          break;
        default: {
          this.leftTextClass = [
            " text_family-r text_size-14 text_weight-400 text_color-a2a2a2",
          ];
          this.rightTextClass = [
            " box-m-r-10 text_size-14 text_family-r text_weight-400 text_color-a2a2a2",
          ];
          this.rightIconClass = ["right_img img-12"];
          this.rightIconShow = true;
        }
      }
    },
    onLeftClick() {
      this.$emit("onLeft");
    },
    onRightClick() {
      this.$emit("onRight");
    },
    onCellClick(){
      this.$emit('cellClick')
    }
  },
};
</script>

<style lang="scss" scope>
@include b(left) {
  align-items: center;
  @include e(img) {
    flex-shrink: 0;
  }
}
@include b(right) {
  align-items: center;
  @include e(img) {
    flex-shrink: 0;
  }
}

@include b(width) {
  @include m(70) {
    width: 70px;
  }
}

@include b(text) {
  @include e(family) {
    @include m(r) {
      font-family: PingFangSC-Regular, PingFang SC;
    }
    @include m(s) {
      font-family: PingFangSC-Semibold, PingFang SC;
    }
  }
  @include e(weight) {
    @include m(400) {
      font-weight: 400;
    }
    @include m(600) {
      font-weight: 600;
    }
  }
  @include e(size) {
    @include m(12) {
      font-size: 12px;
    }
    @include m(14) {
      font-size: 14px;
    }
    @include m(16) {
      font-size: 14px;
    }
  }
  @include e(color) {
    @include m(333) {
      color: #333;
    }
    @include m(c10000) {
      color: #c10000;
    }
    @include m(f46a17) {
      color: #f46a17;
    }
    @include m(eb6100) {
      color: #eb6100;
    }
    @include m(a2a2a2) {
      color: #a2a2a2;
    }
    @include m(939393) {
      color: #939393;
    }
  }
}
@include b(img) {
  @include m(12) {
    width: 12px;
    height: 12px;
  }
  @include m(11) {
    width: 11px;
    height: 11px;
  }
  @include m(14) {
    width: 14px;
    height: 14px;
  }
  @include m(18) {
    width: 18px;
    height: 18px;
  }
  @include m(21) {
    width: 21px;
    height: 21px;
  }
}
@include b(direction) {
  @include m(row) {
    flex-direction: row !important;
  }
  @include m(rerow) {
    flex-direction: row-reverse !important;
  }
}
@include b(hide) {
  display: none !important;
}
</style>
